import React, { useState, useEffect } from "react";
import "./ProductListing.css";
import LoginModel from "../../Authentication/LoginModel/LoginModel";
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import DeliveryLocation from "../Modals/DeliveryLocation";
import Footer from "../../Footer/Footer";
import { shortIcon, filterIcon, closeIcon } from "../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ALGOLIA_ID,
  ALGOLIA_KEY,
  ALGOLIA_SELLER_INDEX,
  ALGOLIA_SPARE_INDEX,
} from "../../../constants";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Configure,
  connectRange,
  connectStateResults,
} from "react-instantsearch-dom";
import FilterSection from "./FilterSection";
import Loader from "../../SubComponent/Loader";
import CustomHits from "./CustomHits";
import SpareHits from "./SpareHits";
import { filterAttribute } from "./FilterAttribute";
import Slider from "@mui/material/Slider";
import CustomerInfo from "./CustomerInformation/CustomerInfoModel";
import CombinedHits from "./CombinedHits";
import SpareFilterSection from "./FilterSection/SpareFilterSection";
import CategoryPopup from "../../Account/Popup/CategoryPopup";

const Store = () => {
  const [loading, setLoading] = useState(false);
  const [showDeliveryPopup, setShowDeliveryPopup] = useState(false);
  const client = algoliasearch(ALGOLIA_ID, ALGOLIA_KEY);
  const [filteredHits, setFilteredHits] = useState([]);
  const location = useLocation();
  const [isMobCategoryShow, setIsMobCategoryShow] = useState(
    window.innerWidth <= 767
  );
  const [activeTab, setActiveTab] = useState("machines");
  const [MainFilters, setMainFilters] = useState("");
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    industry: [],
    category: [],
    subcategory: [],
    brand: [],
    Model: [],
    Price: [],
    Year: [],
    Controller: [],
    XAXIS: [],
    YAXIS: [],
    ZAXIS: [],
    spindlespeed: [],
    spindletaper: [],
    tailstock: [],
    barcapacity: [],
    chucksize: [],
    maxturninglength: [],
    swingoverbed: [],
    swingovercrossslide: [],
    subSpindle: [],
    liveTooling: [],
    orderBy: "",
    page: 1,
  });
  const [IndustryCheckBoxValue, setIndustryCheckBoxValue] = useState([]);
  const [CategoryCheckBoxValue, setCategoryCheckBoxValue] = useState([]);
  const [showShortList, setShowShortList] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    Category: true,
    "Sub-Category": true,
    Brands: true,
    industry: true,
    Grade: true,
    category: false,
    brand: false,
    model: false,
    Price: false,
    year: false,
    Controller: false,
    XAXIS: false,
    YAXIS: false,
    ZAXIS: false,
    spindlespeed: false,
    spindletaper: false,
    tailstock: false,
    barcapacity: false,
    chucksize: false,
    maxturninglength: false,
    swingoverbed: false,
    swingovercrossslide: false,
    subSpindle: false,
    liveTooling: false,
  });
  const searchParams = new URLSearchParams(location.search);
  const searchInput = searchParams.get("searchInput");
  const [Sorting, setSorting] = useState(null);
  const isBrandSelected = filters.brand.length > 0;

  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [deliveryAvailable, setDeliveryAvailable] = useState(true);
  const [seeMore, setSeeMore] = useState(false);
  const navigate = useNavigate();
  const [combinedResults, setCombinedResults] = useState({
    sellerResults: [],
    spareResults: [],
  });
  const [isSpareIndex, setIsSpareIndex] = useState(true);

  const breadcrumbsItems = [{ name: "Industrial Lubricants", link: "/" }];

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedLocation = localStorage.getItem("deliveryLocation");
    if (storedLocation && !deliveryLocation) {
      setDeliveryLocation(storedLocation);
      checkDeliveryAvailability(storedLocation);
    }
  }, []);

  const { SparefilterConfigurations } = filterAttribute(
    IndustryCheckBoxValue,
    CategoryCheckBoxValue
  );

  const handleFilters = (name, value) => {
    setActiveFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const fetchResults = async () => {
      try {
        if (!searchInput) {
          setIsSpareIndex(true);
          setCombinedResults({ sellerResults: [], spareResults: [] });
          return;
        }

        const searchTerms = searchInput.split(" ");
        const queries = searchTerms.flatMap((term) => [
          { indexName: ALGOLIA_SELLER_INDEX, query: term },
          { indexName: ALGOLIA_SPARE_INDEX, query: term },
        ]);

        const { results } = await client.multipleQueries(queries);

        const sellerResults =
          results.find((result) => result.index === ALGOLIA_SELLER_INDEX)
            ?.hits || [];
        const spareResults =
          results.find((result) => result.index === ALGOLIA_SPARE_INDEX)
            ?.hits || [];

        setCombinedResults({
          sellerResults,
          spareResults,
        });

        setIsSpareIndex(spareResults.length > sellerResults.length);
      } catch (error) {
        console.error("Error fetching combined results:", error);
      }
    };

    fetchResults();
  }, [searchInput]);

  const checkDeliveryAvailability = async (pincode) => {
    try {
      const response = await fetch(
        "https://ompextension.origa.market/api/getdeliverypincode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pincode }),
        }
      );
      const data = await response.json();
      setDeliveryAvailable(data.available_status === "available");
      if (data.available_status === "available") {
        setDeliveryLocation(pincode);
        localStorage.setItem("deliveryLocation", pincode);
      } else {
        setShowDeliveryPopup(false);
      }
    } catch (error) {
      console.error("Error checking delivery availability:", error);
      setDeliveryAvailable(false);
    }
  };

  /* const onGetPinCodeHandler = (pin) => {
    checkDeliveryAvailability(pin);
  }; */
  const onGetPinCodeHandler = (pin) => {
    if (pin && pin.length === 6) {
      setDeliveryLocation(pin);
      localStorage.setItem("deliveryLocation", pin); 
      checkDeliveryAvailability(pin);  
    } else {
      console.error("Invalid pincode");
    }
  };

  const handleChangeSort = (sort) => {
    setSorting(sort);
    const selectedFilters = { ...filters, orderBy: sort, page: 1 };
    setFilters(selectedFilters);
    setShowShortList(false);
  };

  const handleSeeMore = (status) => {
    setSeeMore(status);
    document.body.classList.toggle("no-overflow", status);
  };

  const toggleDropdown = () => {
    console.log("First entering into this function");
    const dropdownElement = document.querySelector(".p-fixed");
    const dropdownElement1 = document.querySelector(".fixed-category-bottom");
    if (dropdownElement) {
      dropdownElement.classList.remove("dropdownHidden");
      dropdownElement1.classList.remove("dropdownHidden");
    }
  };

  const RangeSlider = ({ min, max, currentRefinement, refine }) => {
    const handleChange = (event, newValue) => {
      const currentYear = new Date().getFullYear();
      const minAllowedYear = currentYear - 25;
      if (minAllowedYear === newValue[0]) {
        refine({ min: 1900, max: newValue[1] });
      } else {
        refine({ min: newValue[0], max: newValue[1] });
      }
    };
    const [minYear, setMinYear] = useState(1940);

    useEffect(() => {
      const currentYear = new Date().getFullYear();
      const minAllowedYear = currentYear - 25;
      setMinYear(minAllowedYear);
    }, []);

    const defaultValue = [
      currentRefinement.min || min,
      currentRefinement.max || max,
    ];
    return (
      <Slider
        min={minYear}
        max={max}
        value={defaultValue}
        onChange={handleChange}
        valueLabelDisplay="auto"
        color="secondary"
        aria-labelledby="range-slider"
        getAriaValueText={(value) => `$${value}`}
      />
    );
  };

  const CustomRangeInput = connectRange(RangeSlider);

  return (
    <>
      {loading && <Loader />}
      {seeMore && (
        <DeliveryLocation
          modalAction={handleSeeMore}
          pinvalue={onGetPinCodeHandler}
        />
      )}
      <div className="container-fluid col-cust">
        <div className="max-container my-3">
          <InstantSearch
            searchClient={client}
            indexName={ALGOLIA_SPARE_INDEX}
            insights={true}
          >
            <Configure
              maxValuesPerFacet={50}
              query={searchInput || ""}
              filters={MainFilters}
              hitsPerPage={12}
            />
            <div className=" d-md-none ">
              <CategoryPopup
                filterConfigurations={SparefilterConfigurations}
                activeFilters={activeFilters}
                setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                CategoryCheckBoxValue={CategoryCheckBoxValue}
                setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                MainFilters={MainFilters}
                setMainFilters={setMainFilters}
              />
            </div>
            {/* 
                        <Breadcrumbs backnavi={() => navigate('/store')} boldtitle={"Lubricants"} items={breadcrumbsItems} />
                    
                        <div className="head-wrap">
                            <div className="heading-wrap">
                                <div className="sub-heading heading-400-16">Search Result for</div>
                                <div className="heading heading-600-20">{searchInput}</div>
                            </div>
                            <div className="btn-wrap">
                                <button onClick={() => handleSeeMore(true)} className="btn location">
                                    {deliveryLocation ? `Delivery Location : ${deliveryLocation}` : 'Set Delivery Location'}
                                </button>
                            </div>
                        </div> */}
            <div className=" d-flex justify-content-between">
              <div>
                <Breadcrumbs
                  backnavi={() => navigate("/store")}
                  boldtitle={"Lubricants"}
                  items={breadcrumbsItems}
                />
              </div>
            </div>

            <div className="head-wrap d-flex justify-content-end  ">
              <div className="btn-wrap">
                <button
                  onClick={() => handleSeeMore(true)}
                  className="btn location"
                >
                  {deliveryLocation
                    ? `Delivery Location : ${deliveryLocation}`
                    : "Set Delivery Location"}
                </button>

                <div className="short-wrap">
                  <button
                    className="btn short-btn d-md-none"
                    onClick={toggleDropdown}
                  >
                    Filters
                    {filterIcon({ width: 22, height: 22, fill: "#73509E" })}
                  </button>
                </div>
              </div>
            </div>
            <span className="middletextres d-flex justify-content-center mt-2 text-responsive">
              ORIGA offers range of high quality industrial oils
            </span>
            <div className="row layout-wrap">
              <div className="col col-lg-3 col-md-3 col-12">
                <div className="filter-wrap">
                  <div className="head">
                    <div className="heading heading-600-20">
                      {filterIcon({ width: 18, height: 12 })}
                      Filters
                    </div>
                    <div
                      id="ResetBtn"
                      className="heading-600-14 curser-pointer"
                    >
                      Reset
                    </div>
                  </div>
                  {SparefilterConfigurations?.map((config) => (
                    <SpareFilterSection
                      key={config.filterType}
                      title={config.title}
                      attribute={config.attribute}
                      filterType={config.filterType}
                      activeFilters={activeFilters}
                      setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                      CategoryCheckBoxValue={CategoryCheckBoxValue}
                      setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                      handleFilters={handleFilters}
                      MainFilters={MainFilters}
                      setMainFilters={setMainFilters}
                    />
                  ))}
                  {isBrandSelected && (
                    <SpareFilterSection
                      title="Grade"
                      attribute="grade"
                      filterType="grade"
                      activeFilters={activeFilters}
                      setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                      CategoryCheckBoxValue={CategoryCheckBoxValue}
                      setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                      handleFilters={handleFilters}
                      MainFilters={MainFilters}
                      setMainFilters={setMainFilters}
                    />
                  )}
                </div>
              </div>
              <div className="col col-lg-9 col-md-9 col-12">
                {isSpareIndex ? (
                  <SpareHits
                    setLoading={setLoading}
                    Sorting={Sorting}
                    hitComponent={filteredHits}
                    query={searchInput}
                    deliveryAvailable={deliveryAvailable}
                  />
                ) : (
                  <CombinedHits
                    sellerResults={combinedResults.sellerResults}
                    spareResults={combinedResults.spareResults}
                    deliveryAvailable={deliveryAvailable}
                  />
                )}
              </div>
            </div>
          </InstantSearch>
        </div>
      </div>
      <Footer />
      {showDeliveryPopup && (
        <div className="  popup-overlay ">
          <div className="popup-bannavjer ">
            <div className="popup-content ">
              <div className="">
                <h3 className="">Thank you for your quotation request!</h3>
                <p className="wewillcontactu">We'll get back to you soon</p>
                <br></br>
                <div
                  className="close-popup"
                  onClick={() => setShowDeliveryPopup(false)}
                >
                  {closeIcon({ width: 14, height: 14 })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Store;
